<template>
  <div class="progress-bar-wrapper">
    <slot />
    <div class="progress-bar">
      <div class="progress"></div>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{ progress: number }>();
const width = computed(() => `${props.progress}%`);
</script>

<style lang="scss" scoped>
.progress-bar-wrapper {
  display: flex;
  gap: 1rem;
  align-items: center;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
}
.progress-bar {
  width: 100%;
  background-color: $light-80;
  height: 0.75rem;
  border-radius: $border-radius;
  overflow: hidden;

  .progress {
    background-color: var(--primary);
    height: 100%;
    width: v-bind(width);
    transition: width 0.2s linear;
    border-radius: $border-radius;
  }
}

.progress-gray .progress {
  background-color: $dark-50;
}
</style>
